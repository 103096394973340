import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        return next({
          name: "Agents"
        });
      }
      next();
    }
  },
  {
    path: "/agents",
    name: "Agents",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Agents/Agents.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/deleted-agents",
    name: "DeletedAgents",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Agents/DeletedAgents.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/doctors",
    name: "Doctors",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Doctors/Doctors.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/deleted-doctors",
    name: "DeletedDoctors",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Doctors/DeletedDoctors.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/suggested-doctors",
    name: "SuggestedDoctors",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Doctors/SuggestedDoctors.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/doctors-updates",
    name: "UpdateSuggestedDoctors",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Doctors/DoctorsUpdates.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/doctors-created-by-users",
    name: "DoctorsAddedByUsers",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Doctors/DoctorsAddedByUsers.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/orders",
    name: "Orders",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Orders.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/doctors/:id/orders",
    name: "SingleDoctorOrders",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Doctors/SingleDoctorOrders.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/doctors/:id/updates",
    name: "DoctorsSuggestedUpdates",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Doctors/DoctorsSuggestedUpdates.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/doctors/:id/active-daily-plan",
    name: "SingleDoctorActiveDailyPlan",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Doctors/SingleDoctorActiveDailyPlan.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/doctors/:id/dates",
    name: "SingleDoctorDates",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Doctors/SingleDoctorDates.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/agents/:id",
    name: "SingleAgent",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Agents/SingleAgent.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/agents/:id/active-daily-plan",
    name: "SingleAgentActiveDailyPlan",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Agents/SingleAgentActiveDailyPlan.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/agents/daily-plan/:id/doctors",
    name: "SingleAgentDailyPlanDoctors",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Agents/SingleAgentDailyPlanDoctors.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/agents/doctor-visit/:id/items",
    name: "DoctorVisitItems",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Doctors/DoctorVisitItems.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/agents/:id/dates",
    name: "SingleAgentDates",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Agents/SingleAgentDates.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/agents/:id/items",
    name: "SingleAgentItems",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Agents/SingleAgentItems.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/agents/:id/orders",
    name: "SingleAgentOrders",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Agents/SingleAgentOrders.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  
  {
    path: "/agents/:id/cities",
    name: "SingleAgentCities",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Agents/SingleAgentCities.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/agents/:id/medical-fields",
    name: "SingleAgentMedicalFields",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Agents/SingleAgentMedicalFields.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/agents/:id/notes",
    name: "SingleAgentNotes",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Agents/SingleAgentNotes.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  
  {
    path: "/agents/:id/reports",
    name: "SingleAgentReports",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Agents/SingleAgentReports.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/agents/:id/daily-plans",
    name: "SingleAgentDailyPlans",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Agents/SingleAgentDailyPlans.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  
  {
    path: "/regions",
    name: "Regions",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/Regions.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/countries",
    name: "Countries",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/Countries.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/scientific-items",
    name: "ScientificItems",
    component: () =>
      import(/* webpackChunkName: "scientific-items" */ "../views/ScientificItems.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/subscription-and-pin-codes",
    name: "SubscriptionTypesAndPinCodes",
    component: () =>
      import(/* webpackChunkName: "scientific-items" */ "../views/SubscriptionTypesAndPinCodes.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/ads",
    name: "Ads",
    component: () =>
      import(/* webpackChunkName: "scientific-items" */ "../views/Ads.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    component: () =>
      import(/* webpackChunkName: "scientific-items" */ "../views/Suppliers.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
   {
    path: "/supplier/:id/users",
    name: "SupplierAgents",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/SupplierAgents.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/medical-fields",
    name: "MedicalFields",
    component: () =>
      import(/* webpackChunkName: "scientific-items" */ "../views/MedicalFields.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/medical-fields/:id/users",
    name: "MedicalFieldsAgents",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/MedicalFieldsAgents.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/admins",
    name: "Admins",
    component: () =>
      import(/* webpackChunkName: "scientific-items" */ "../views/Admins.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/Settings.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/daily-plan/settings",
    name: "DailyPlanSettings",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/DailyPlanSettings.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/questions",
    name: "Questions",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/Questions.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/Notifications.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/notification/:id/recivers",
    name: "NotificationRecivers",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/NotificationRecivers.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
 

  {
    path: "/cms/faq",
    name: "Faq",
    component: () =>
      import(/* webpackChunkName: "add" */ "../views/CMS/FAQ.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
    
  },
  
  // {
  //   path: "/fastlink/gp-management",
  //   name: "gp-management",
  //   component: () =>
  //     import(/* webpackChunkName: "add" */ "../views/Fastlink/GP.vue"),
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters["auth/authenticated"]) {
  //       return next({
  //         name: "login"
  //       });
  //     }
  //     next();
  //   }
  // },
  // {
  //   path: "/fastlink/shops-management",
  //   name: "shops-management",
  //   component: () =>
  //     import(/* webpackChunkName: "add" */ "../views/Fastlink/Shops.vue"),
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters["auth/authenticated"]) {
  //       return next({
  //         name: "login"
  //       });
  //     }
  //     next();
  //   }
  // },
  // {
  //   path: "/fastlink/shops-management/:id/details",
  //   name: "ViewShop",
  //   component: () =>
  //     import(/* webpackChunkName: "add" */ "../views/Fastlink/Shops/View.vue"),
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters["auth/authenticated"]) {
  //       return next({
  //         name: "login"
  //       });
  //     }
  //     next();
  //   }
  // },
  // {
  //   path: "/fastlink/shops-management/add",
  //   name: "AddShop",
  //   component: () =>
  //     import(/* webpackChunkName: "add" */ "../views/Fastlink/Shops/Add.vue"),
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters["auth/authenticated"]) {
  //       return next({
  //         name: "login"
  //       });
  //     }
  //     next();
  //   }
  // },
  // {
  //   path: "/fastlink/shops-management/:id/edit",
  //   name: "EditShop",
  //   component: () =>
  //     import(/* webpackChunkName: "add" */ "../views/Fastlink/Shops/Edit.vue"),
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters["auth/authenticated"]) {
  //       return next({
  //         name: "login"
  //       });
  //     }
  //     next();
  //   }
  // },

  // {
  //   path: "/fastlink/shops-management/:id/product/add",
  //   name: "AddProduct",
  //   component: () =>
  //     import(/* webpackChunkName: "add" */ "../views/Fastlink/Products/Add.vue"),
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters["auth/authenticated"]) {
  //       return next({
  //         name: "login"
  //       });
  //     }
  //     next();
  //   }
  // },
  // {
  //   path: "/fastlink/shops-management/product/:id/edit",
  //   name: "EditProduct",
  //   component: () =>
  //     import(/* webpackChunkName: "add" */ "../views/Fastlink/Products/Edit.vue"),
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters["auth/authenticated"]) {
  //       return next({
  //         name: "login"
  //       });
  //     }
  //     next();
  //   }
  // },
  // {
  //   path: "/fastlink/admins",
  //   name: "admins",
  //   component: () =>
  //     import(/* webpackChunkName: "add" */ "../views/Fastlink/Admins.vue"),
  //   beforeEnter: (to, from, next) => {
  //     if (!store.getters["auth/authenticated"]) {
  //       return next({
  //         name: "login"
  //       });
  //     }
  //     next();
  //   }
  // },

  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile/Profile.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
 
    {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "myads" */ "../views/NotFound.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  { path: "/:pathMatch(.*)*", redirect: "/404" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
