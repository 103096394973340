export default {
  methods: {
    getLocalPhone(phone) {
      if (phone == null) {
        return "لا توجد بيانات";

      }
      let modifiedPhone = phone.substring(4);
      modifiedPhone = "0" + modifiedPhone;

      modifiedPhone = modifiedPhone ?? "لا توجد بيانات";
      return modifiedPhone;
    }

  },
};
