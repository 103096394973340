export default {
  methods: {
    getDate(item) {
      var dateParts = item.split(/\D+/);
      var returnDate = new Date();
      returnDate.setUTCFullYear(parseInt(dateParts[0]));
      returnDate.setUTCMonth(parseInt(dateParts[1] - 1));
      returnDate.setUTCDate(parseInt(dateParts[2]));
      returnDate.setUTCHours(parseInt(dateParts[3]));
      returnDate.setUTCMinutes(parseInt(dateParts[4]));
      returnDate.setUTCSeconds(parseInt(dateParts[5]));
      returnDate.setUTCMilliseconds(parseInt(dateParts[6]));
      var timezoneOffsetHours = 0;
      if (dateParts[7] || dateParts[8]) {
        var timezoneOffsetMinutes = 0;
        if (dateParts[8]) {
          timezoneOffsetMinutes = parseInt(dateParts[8]) / 60;
        }
        timezoneOffsetHours = parseInt(dateParts[7]) + timezoneOffsetMinutes;
        if (item.created_at.substr(-6, 1) == "+") {
          timezoneOffsetHours *= -1;
        }
      }
      returnDate.setHours(returnDate.getHours() + timezoneOffsetHours);
      var d = new Date(returnDate),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("/");
    },

    getHour(item) {
      var dateParts = item.split(/\D+/);
      var returnDate = new Date();
      returnDate.setUTCFullYear(parseInt(dateParts[0]));
      returnDate.setUTCMonth(parseInt(dateParts[1] - 1));
      returnDate.setUTCDate(parseInt(dateParts[2]));
      returnDate.setUTCHours(parseInt(dateParts[3]));
      returnDate.setUTCMinutes(parseInt(dateParts[4]));
      returnDate.setUTCSeconds(parseInt(dateParts[5]));
      returnDate.setUTCMilliseconds(parseInt(dateParts[6]));
      var timezoneOffsetHours = 0;
      if (dateParts[7] || dateParts[8]) {
        var timezoneOffsetMinutes = 0;
        if (dateParts[8]) {
          timezoneOffsetMinutes = parseInt(dateParts[8]) / 60;
        }
        timezoneOffsetHours = parseInt(dateParts[7]) + timezoneOffsetMinutes;
        if (item.created_at.substr(-6, 1) == "+") {
          timezoneOffsetHours *= -1;
        }
      }
      returnDate.setHours(returnDate.getHours() + timezoneOffsetHours);
      var d = new Date(returnDate),
        hour = "" + d.getHours(),
        minutes = "" + d.getMinutes();

      var ampm = hour >= 12 ? 'pm' : 'am';
      hour = hour % 12;
      hour = hour ? hour : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      var strTime = hour + ':' + minutes + ' ' + ampm;
      return strTime;
    },
    convertToAmPm(time24) {
      // Split the time string into hours and minutes
      const [hours24, minutes] = time24.split(':').map(Number);

      // Determine the period (am or pm)
      const period = hours24 >= 12 ? 'مساءً' : 'صباحاً';

      // Convert hours from 24-hour to 12-hour format
      const hours12 = hours24 % 12 || 12;

      // Format the hours and minutes with leading zero if needed
      const formattedHours = hours12.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');

      return `${formattedHours}:${formattedMinutes} ${period}`;
    }


  },
};
